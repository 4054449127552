// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bv_k4";
export var caseStudyBackground__lineColor = "bv_kW";
export var caseStudyHead__imageWrapper = "bv_kT";
export var caseStudyProjectsSection = "bv_k5";
export var caseStudyQuote__bgLight = "bv_k2";
export var caseStudyQuote__bgRing = "bv_k1";
export var caseStudyQuote__bgRingDark = "bv_lY";
export var caseStudyQuote__bottomVideo = "bv_lZ";
export var caseStudySolution__ring = "bv_kZ";
export var caseStudySolution__ringTwo = "bv_k0";
export var caseStudyVideo = "bv_k7";
export var caseStudyVideo__ring = "bv_k8";
export var caseStudy__bgDark = "bv_kS";
export var caseStudy__bgLight = "bv_kR";